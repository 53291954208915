.h1Cover{
  color: white !important;
}

.greenText {
  color: #1ebd54;
}

.onMediaContainer{
  background-color: #fff;
  border: solid;
  border-width: 2px 2px 4px 2px;
  border-color: #e5e5e5;
  border-radius: 24px;
  overflow: hidden;
}

.onMediaContainer img{
  width: 100%;
}
