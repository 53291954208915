.container_banner {
  position: relative;
  background: radial-gradient(
    137.92% 137.92% at 49.89% 0%,
    #e4aaf2 0%,
    #7d188e 55.67%,
    #54085b 100%
  );
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: rgba(37, 1, 51, 1);
  box-shadow: 0px 8px 16px 2px rgba(13, 13, 13, 0.25);
  border-radius: 24px;
  color: #fff;
  backdrop-filter: blur(8px);
}

.backgroundStripes {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 4px,
    rgba(255, 255, 255, 1) 4px
  );
  opacity: 0.05;
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 1)
  );
  background-size: 10px 10px;
  z-index: 1;
}

.bannerBadgePrice {
  font-family: transducer;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: #189944;
  background-color: #d1fadf;
  padding: 8px 16px;
  border-radius: 50px;
}
