.iconList {
  padding: 16px;
  background-color: #f9e7fe;
  border-radius: 50%;
  line-height: 8px;
}

.iconList svg {
  fill: #7d188e;
  height: 32px;
  width: 32px;
}

@media (min-width: 900px) {
  .badgeRecommendedPlan {
    border-radius: 50px;
    background-color: #1ebd54;
    padding: 8px 16px;
    font-family: transducer;
    color: #d1fadf;
    font-size: 12px;
    line-height: 1rem;
    font-weight: 600;
    position: absolute;
    top: -16px;
    margin: 0 auto;
  }

  .badgeVacationNumber {
    border-radius: 50px;
    background-color: #f9e7fe;
    padding: 8px 16px;
    font-family: transducer;
    color: #7d188e;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
  .planPriceText {
    font-size: 40px;
    line-height: 40px;
    font-family: transducer;
    font-weight: 600;
  }

  .planTable {
    position: absolute;
    bottom: 96px;
    right: 0;
    z-index: 1;
  }

  .planTableLine:first-child{
    border-top-left-radius: 16px;
  }
  
  .planTableLine:last-child{
    border-bottom-left-radius: 16px;
  }
}

@media (max-width: 900px) {
  .badgeRecommendedPlan {
    border-radius: 50px;
    background-color: #1ebd54;
    padding: 4px 16px;
    font-family: transducer;
    color: #d1fadf;
    font-size: 8px;
    line-height: 1rem;
    font-weight: 600;
    position: absolute;
    top: -16px;
    margin: 0 auto;
  }

  .badgeVacationNumber {
    border-radius: 50px;
    background-color: #f9e7fe;
    padding: 4px 8px;
    font-family: transducer;
    color: #7d188e;
    font-size: 8px;
    line-height: 16px;
    font-weight: 600;
  }

  .planPriceText {
    font-size: 24px;
    line-height: 32 px;
    font-family: transducer;
    font-weight: 600;
  }

  .planTable {
    position: absolute;
    bottom: 128px;
    right: 0;
    z-index: 2;
  }

  .planTableLine{
    border-top: 1px solid #CCC;
  }

  .planTableLine:first-child{
    border:none;
  }

  .planTableLine:first-child .planTableOddLine{
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .planTableLineRowMobile{
    border-top: 1px solid #ccc;
  }
}


.wrapperPlanPrice span {
  margin-right: 4px;
}

.currencyText {
  font-size: 12px;
  line-height: 16px;
  font-family: transducer;
  font-weight: 600;
}

.installmentsText {
  font-size: 12px;
  line-height: 16px;
  font-family: transducer;
  font-weight: 600;
  color: #999;
}

.planIcon{
  height: 16px;
  width: 16px;
  line-height: 8px;
  fill: #1ebd54;

  /* safari workaround */
  font-size: 16px !important;
  color: #1ebd54;
}

.cancelIcon {
  fill: #bfbfbf;

  /* safari workaround */
  color: #bfbfbf;
}


.planTableOddLine{
  background-color: #F2F2F2;
}
