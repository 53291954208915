.carrouselTitle{
    color: #666;
    text-align: center;
}

.containerEnterprises{
    display: flex;
    flex-direction: row;
}

.imgEnterprise{
    height: 32px;
    margin-right: 56px;
}