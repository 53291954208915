.hero_highlight {
  font-weight: 900;
  color: #1ebd54;
}

.hero_item {
  border-left: 2px solid #7d188e;
  border-radius: 24px;
  background: radial-gradient(
      93.18% 2368.5% at 2.23% 6.82%,
      rgba(194, 145, 242, 0.9) 0%,
      rgba(225, 202, 244, 0.9) 29.17%,
      rgba(230, 179, 255, 0) 85.12%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.hero_badge {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 8px;
  line-height: 8px;
}

.hero_badge > svg {
  fill: #7d188e;
}

.backgroundRadialPurple {
  position: relative;
  background: radial-gradient(
    99.15% 99.15% at 50% -1.7%,
    #ce85f2 14%,
    #9e3dcc 49.42%,
    #6d1099 77.55%,
    #46024d 100%
  );
}

.titlePerfectMatch {
  color: #fff;
}

.matchDataDisplay {
  position: relative;
  padding: 80px 40px;
  background: radial-gradient(
    137.92% 137.92% at 49.89% 0%,
    rgba(255, 255, 255, 0.75) 35.27%,
    rgba(255, 255, 255, 0) 100%
  );
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #b845e6;
  box-shadow: 0px 8px 16px 2px rgba(13, 13, 13, 0.25);
  border-radius: 24px;
  color: #46024d;
  backdrop-filter: blur(8px);
}

.backgroundStripes {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 5px,
    rgba(255, 255, 255, 1) 5px
  );
  opacity: 0.25;
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 1)
  );
  background-size: 10px 10px;
  z-index: 1;
}

.bgImgPerfectMatch {
  position: absolute;
}

@media (max-width: 960px) {
  .bgImgPerfectMatch {
    top: 40px;
    left: -150px;
    overflow: hidden;
  }

  .bgImgPerfectMatch img{
    width: 150%;
  }
}

@media (min-width: 960px) {
  .bgImgPerfectMatch {
    top: 0;
    left: 0;
  }
  .bgImgPerfectMatch img {
    width: 160%;
  }
}

.iconBenefit {
  padding: 16px;
  background: #f9e7fe;
  border-radius: 50px;
  line-height: 8px;
}

.iconBenefit svg {
  height: 32px;
  width: 32px;
  fill: #7d188e;
}

.descriptionProductSection {
  color: #fff;
}

.productWhiteDisplay {
  color: #0d0d0d;
  background: linear-gradient(
    311.53deg,
    rgba(255, 255, 255, 0.15) 17.78%,
    #ffffff 65.35%
  );
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 24px;
  position: relative;
}

.productDisplay {
  color: #fff;
  position: relative;
  background: linear-gradient(
    311.53deg,
    rgba(192, 101, 224, 0.2) 1.65%,
    #46024d 82.56%
  );
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #3a0540;
  border-radius: 24px;
}

.backgroundStripesProductWhite {
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 5px,
    rgba(255, 255, 255, 1) 5px
  );
  opacity: 0.25;
  mask-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 1)
  );
  background-size: 10px 10px;
  z-index: 1;
}

.backgroundStripesProduct {
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 5px,
    rgba(255, 255, 255, 1) 5px
  );
  opacity: 0.1;
  mask-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 1)
  );
  background-size: 10px 10px;
  z-index: 1;
}

.itemListWhiteProduct {
  padding: 16px;
  background: radial-gradient(
    93.18% 2368.5% at 2.23% 6.82%,
    #bb85f0 0%,
    #e6c9ff 15.11%,
    rgba(173, 101, 245, 0) 85.12%
  );
  border-left: 2px solid #7d188e;
  border-radius: 16px;
  backdrop-filter: blur(8px);
}

.iconProduct {
  background-color: #f9e7fe;
  border-radius: 8px;
  line-height: 8px;
  padding: 8px;
}

.iconProduct svg {
  fill: #7d188e;
}

.itemListProduct {
  padding: 16px;
  background: radial-gradient(
    93.18% 2368.5% at 2.23% 6.82%,
    rgba(194, 145, 242, 0.5) 0%,
    rgba(168, 112, 224, 0.5) 0.01%,
    rgba(225, 202, 244, 0.5) 16.61%,
    rgba(168, 112, 224, 0) 85.12%
  );
  border-left: 2px solid #e4aaf2;
  border-radius: 16px;
  backdrop-filter: blur(8px);
}
