.greenText {
  color: #1ebd54;
}

.iconBenefit {
  padding: 16px;
  background: #d1fadf;
  border-radius: 50px;
  line-height: 8px;
}

.iconBenefit svg {
  height: 32px;
  width: 32px;
  fill: #0d8034;
}

p {
  white-space: pre-wrap;
}

.backgroundRadialPurple {
  background: radial-gradient(
    99.15% 99.15% at 50% -1.7%,
    #52cc7b 14%,
    #22d960 49.42%,
    #189944 77.55%,
    #0a6629 100%
  );
}

.titlePerfectMatch {
  color: #fff;
}

.matchDataDisplay {
  position: relative;
  padding: 80px 40px;
  background: radial-gradient(
    137.92% 137.92% at 49.89% 0%,
    rgba(255, 255, 255, 0.75) 35.27%,
    rgba(255, 255, 255, 0) 100%
  );
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #0d8034;
  box-shadow: 0px 8px 16px 2px rgba(13, 13, 13, 0.25);
  border-radius: 24px;
  color: #0d8034;
  backdrop-filter: blur(8px);
}

.backgroundStripes {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 5px,
    rgba(255, 255, 255, 1) 5px
  );
  opacity: 0.25;
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 1)
  );
  background-size: 10px 10px;
  z-index: 1;
}

@media (min-width: 900px) {
  .bgImgPerfectMatch {
    position: absolute;
    top: 112px;
    right: 80px;
  }


.bgImgPerfectMatch img {
  width: 98%;
}
}

@media (max-width: 900px) {
  .bgImgPerfectMatch {
    position: absolute;
    top: 150px;
    right: 50px;
  }

  .bgImgPerfectMatch img {
    width: 120%;
  }
}


.titleTestimonials {
  color: #666;
}

.testimonial {
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #e6e6e6;
  border-radius: 24px;
  opacity: 0.75;
}

.testimonial p {
  font-size: 12px;
  line-height: 16px;
}

.testimonial img {
  height: 40px;
  width: 40px;
}

.testimonial h6 {
  font-size: 14px;
}

.activeTestimonial {
  background-color: white;
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #1ebd54;
  box-shadow: 0px 4px 8px 2px rgba(13, 13, 13, 0.25);
  border-radius: 24px;
  transition: ease-in-out 0.5s;
  opacity: 1;
}

.activeTestimonial p {
  font-size: 16px;
  line-height: 24px;
}

.activeTestimonial img {
  height: 64px;
  width: 64px;
}

.activeTestimonial h6 {
  font-size: 16px;
}

.testimonialMobile{
  background-color: white;
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #1ebd54;
  box-shadow: 0px 4px 8px 2px rgba(13, 13, 13, 0.25);
  border-radius: 24px;
}

.testimonialMobile img {
  height: 40px;
  width: 40px;
}

.testimonialMobile h6{
  color:#666;
}
