.logo {
  height: 2rem;
  fill: #1ebd54;
}

.logo_onlySymbol {
  height: 1.5rem;
  fill: #1ebd54;
}

.items_list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Open Sans", sans-serif;
}

.items_list > a {
  text-decoration: none;
  color: #0d0d0d;
  margin-right: 1rem;
}

a[class='active']{
  background-color: #D1FADF !important;
  color: #189944 !important;
  border-radius: 4px;
}

a[class='active'] li{
  background-color: #D1FADF !important;
  color: #189944 !important;
  border-radius: 4px;
  font-weight: bold;
}


.items_list > a:visited {
  color: #0d0d0d;
}

.items_list > a:last-child{
  margin-right: 0px;
}

.items_list > a > li {
  list-style: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 600;
}

.items_list > a > li:hover {
  background-color: #f2f2f2;
}

.items_list > li {
  list-style: none;
  margin-right: 1rem;
  font-weight: 600;
}


.items_list > li > span {
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  cursor: pointer;
}

.items_list > li > span:hover {
  background-color: #f2f2f2;
}

.items_list > li > span.menuOpened {
  background-color: #f2f2f2;
}

.items_list > li > span > svg {
  margin-left: 0.5rem;
  fill: #1ebd54;
}

.items_list > li > span > .dropdownClicked {
  fill: #0d0d0d;
  transform: rotate(-180deg);
  transition: ease-in-out 0.4s;
}

.items_list > a > .signInUp {
  border-radius: 4px;
  padding: 12px 24px;
  background-color: #1ebd54;
  color: #fff;
  font-weight: 600;
}
.items_list > a > .signInUp:hover {
  background-color: #22d960;
}

.dropdownMenu a {
  text-decoration: none;
  color: #0d0d0d;
}

.items_list_mobile {
  display: flex;
  flex-direction: column;
  width: 100vw;
  row-gap: 24px;
  justify-content: center;
  align-items: center;
  list-style: none;
  font-family: "Open Sans", sans-serif;
}

.items_list_mobile a {
  text-decoration: none;
  color: #0d0d0d;
  display: flex;
  flex: 1;
}

.items_list_mobile li {
  list-style: none;
  font-weight: 600;
}

.items_list_mobile a li {
  padding: 12px 24px;
}

.items_list_mobile li span {
  padding: 12px 24px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  cursor: pointer;
}

.btnEnterprise {
  display: flex;
  justify-content: center;
}

.btnEnterpriseOpened {
  background-color: #0d8034;
  color: #fff;
  border-radius: 4px;
}

.btnEnterpriseOpened .dropdownClicked {
  transform: rotate(-180deg);
  transition: ease-in-out 0.4s;
}

.enterpriseOpenedMobile {
  padding: 24px;
  background-color: #f2f2f2;
  border-radius: 16px;
  transform-origin: top;
  transition: ease-in-out 1s;
}


@keyframes openFade {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

@keyframes closeFade {
  0%{
    opacity: 1;
  }

  100%{
    opacity: 0;
  }
}

.enterpriseItems {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 24px;
}

.enterpriseItems a {
  display: flex;
  justify-content: center;
  transition-property: height;
}

.headerSectionProdutcs {
  font-size: 14px;
  line-height: 16px;
  padding: 0px !important;
}

.enterpriseItems ul a {
  display: flex;
  flex-direction: column;
  align-items: center;
}
