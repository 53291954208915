@import url("https://use.typekit.net/crm6njx.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
}

html,
body,
root {
  background-color: #fff;
  color: #0d0d0d;
  height: 100%;
  font-size: 16px;
  line-height: 1.5rem;
  margin: 0;
  font-family: space-grotesk-variable;
  font-style: normal;
  font-variation-settings: "wght" 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 900px) {
  h1 {
    font-family: transducer;
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 4rem;
    margin-block-start: 2rem;
    margin-block-end: 2rem;
  }

  h2 {
    font-family: transducer;
    font-weight: 600;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-block-start: 1.75rem;
    margin-block-end: 1.75rem;
  }

  h3 {
    font-family: transducer;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3rem;
    margin-block-start: 1.5rem;
    margin-block-end: 1.5rem;
  }

  h4 {
    font-family: transducer;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-block-start: 1.25rem;
    margin-block-end: 1.25rem;
  }

  h5 {
    font-family: transducer;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  h6 {
    font-family: transducer;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-block-start: 0.75rem;
    margin-block-end: 0.75rem;
  }

  p {
    font-family: space-grotesk-variable;
    font-style: normal;
    font-variation-settings: "wght" 400;
    margin-block-start: 0.75rem;
    margin-block-end: 0.75rem;
  }

  .sm_body {
    font-family: space-grotesk-variable;
    font-style: normal;
    font-variation-settings: "wght" 400;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }

  .xs_body {
    font-family: space-grotesk-variable;
    font-style: normal;
    font-variation-settings: "wght" 400;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }
}

@media (max-width: 900px) {
  h1 {
    font-family: transducer;
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 3rem;
    margin-block-start: 1.5rem;
    margin-block-end: 1.5rem;
  }

  h2 {
    font-family: transducer;
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-block-start: 1.25rem;
    margin-block-end: 1.25rem;
  }

  h3 {
    font-family: transducer;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  h4 {
    font-family: transducer;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  h5 {
    font-family: transducer;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    margin-block-start: 0.625rem;
    margin-block-end: 0.625rem;
  }

  h6 {
    font-family: transducer;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }

  p {
    font-family: space-grotesk-variable;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-variation-settings: "wght" 400;
    margin-block-start: 0.75rem;
    margin-block-end: 0.75rem;
  }

  .paragraph_style {
    font-family: space-grotesk-variable;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-variation-settings: "wght" 400;
    margin-block-start: 0.75rem;
    margin-block-end: 0.75rem;
  }

  .sm_body {
    font-family: space-grotesk-variable;
    font-style: normal;
    font-variation-settings: "wght" 400;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }

  .xs_body {
    font-family: space-grotesk-variable;
    font-style: normal;
    font-variation-settings: "wght" 400;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
  }
}

.disableMarginBlock {
  margin-block-start: 0px;
  margin-block-end: 0px;
}
