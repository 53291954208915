.logo_footer{
    fill: #666;
    height: 40px;
}

.copyright{
    color: #666;
}

.h6_footer{
    color: #666;
}

