.benefitsLadder {
  background: radial-gradient(
    99.15% 99.15% at 50% -1.7%,
    #ce85f2 14%,
    #9e3dcc 49.42%,
    #6d1099 77.55%,
    #46024d 100%
  );
  color: #fff;
}

.cardBenefitsLadder {
  background: radial-gradient(
    137.92% 137.92% at 49.89% 0%,
    rgba(255, 255, 255, 0.75) 50.98%,
    rgba(255, 255, 255, 0) 100%
  );
  border-width: 2px 2px 4px 2px;
  border-style: solid;
  border-color: #b845e6;
  box-shadow: 0px 8px 16px 2px rgba(13, 13, 13, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 24px;
  color: #46024D;
}

.backgroundStripes {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 24px;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0) 5px,
      rgba(255, 255, 255, 1) 5px
    );
    opacity: 0.25;
    mask-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 1)
    );
    background-size: 10px 10px;
    z-index: 1;
  }


.badge4Reasons{
    padding: 16px;
    background-color: #F9E7FE;
    border-radius: 50px;
    line-height: 8px;
}

.badge4Reasons svg{
    fill: #7D188E;
}